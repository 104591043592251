import React from "react";
import { NavLink } from "react-router-dom";
const sidenav = [
    {
        name: "Dashboard",
        link: "/"
    },
    {
        name: "Business Listings",
        link: "/listings"
    },
    {
        name: "Pending Verifications",
        link: "/pending-verification"
    },
    {
        name: "Advertisements",
        link: "/advertisements"
    },
    {
        name: "Subscriptions",
        link: "/subscriptions"
    },
    // {
    //     name: "User Management",
    //     link: "/users"
    // }
]
export default function Sidenav(attr) {
    
    return (<nav className="col-md-2 d-none d-md-block sidebar">
        <div className="position-sticky">
            <div className="user-info">
            <div  className="name">{ attr?.user?.first_name ?? "" }</div>
            <div className="role">{ attr?.roles ?? "" }</div>
            </div>
            <ul className="nav flex-column">
                { sidenav.map((item, index) => (
                    <li className="nav-item" key={ item.name }>
                        <a className={ "nav-link" + (window.location.pathname === item.link ? " active" : "") } href={ item.link } >
                            {/* <i className="fas fa-fw fa-tachometer-alt"></i> */ }
                            <span>{ item.name }</span>
                        </a>
                    </li>
                )) }
            </ul>
        </div>
        
    </nav>)
}