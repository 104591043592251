import React from "react";
import {API} from "../../utils";

export default function PaymentDue(){
    const [paymentInfo,setPaymentInfo]=React.useState([]);
    const [plans,setPlans]=React.useState({});
    React.useEffect(()=>{
        const api=new API();
        api.get(`/api/v1/businesses`).then(resp=>{
            console.log(resp);
        })
    },[])
    React.useEffect(()=>{
        const api=new API();
        api.get(`/api/v1/plans`).then(resp=>{
            if(!resp.data)return;
            let temp=Array.from(resp.data).reduce((a,c)=>{
                a[c.id]=c;
                return a;
            },{});
            setPlans(temp)
        })
    },[])   
    console.log(plans);
    React.useEffect(()=>{
        let api = new API();
          api
            .get(
              `/api/v1/businesses?start=0&length=1`,
            )
            .then(resp=>{
                console.table(resp.data[0])
            })
    },[])
    return <></>
    return(<>
    {
        paymentInfo.map(info=><>
        <b>id</b>{info.id}<br/>
        <b>activated_at</b>{info.activated_at}<br/>
        <b>amount</b>{info.amount}<br/>
        <b>business</b>{info.business}<br/>
        <b>created_at</b>{info.created_at}<br/>
        <b>currency</b>{info.currency}<br/>
        <b>expires_at</b>{info.expires_at}<br/>
        <b>payment_confirmed_by</b>{info.payment_confirmed_by}<br/>
        <b>payment_date</b>{info.payment_date}<br/>
        <b>payment_mode</b>{info.payment_mode}<br/>
        <b>payment_status</b>{info.payment_status}<br/>
        <b>plan</b>{info.plan}<br/>
        <b>requested_at</b>{info.requested_at}<br/>
        <b>status</b>{info.status}<br/>
        <b>transaction_id</b>{info.transaction_id}<br/>
        <b>updated_at</b>{info.updated_at}<br/>
        </>)
    }
    </>)
}