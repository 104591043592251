import React from "react";
import { Editor as TinyMCE } from "@tinymce/tinymce-react";

export default function Editor(attr) {
  return (
    <>
      <div className="form-group">
        <label htmlFor="">{attr.label}</label>
        <TinyMCE
          name={attr.name}
          apiKey="ynda8xvxsgsxbvvdnmw1xwo1e64i435wywl299td0k07jsle"
          init={{
            menubar: false,
            statusbar: false,
            plugins:
              "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker",
            toolbar:
              "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
          }}
          initialValue={attr.value ?? ""}
        />
      </div>
    </>
  );
}
