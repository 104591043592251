import React from "react";
import { API } from "../utils";
import * as Form from "./Form";
function Aggrement(attr) {
    const acceptTerms = async () => {
        const api = new API();
        await api.get("/api/v1/accept");
        window.location.reload();
    }
    return <div className="container"><p className="MsoNormal"><strong>Franchise Agreement for Onboarding Businesses on Buz4U</strong></p>
        <p className="MsoNormal"><strong>Effective Date</strong>: { attr.user.created_at }</p>
        <p className="MsoNormal">This Franchise Agreement (the &ldquo;Agreement&rdquo;) is made between <strong>Buz4U</strong>&nbsp;("Franchisor") and the individual or entity creating the franchise account ("Franchisee"). By creating an account, the Franchisee agrees to the following terms and conditions.</p>
        <p className="MsoNormal">&nbsp;</p>
        <hr align="center" size="2" width="100%" />
        <p>&nbsp;</p>
        <p className="MsoNormal"><strong>1. Franchisee Details</strong></p>
        <p className="MsoNormal"><strong>Franchisee Name</strong>: { attr.user.first_name }<br />
            <strong>Franchisee Email</strong>:{ attr.user.email }</p>
        <p className="MsoNormal">&nbsp;</p>
        <hr align="center" size="2" width="100%" />
        <p>&nbsp;</p>
        <p className="MsoNormal"><strong>2. Grant of Franchise Rights</strong></p>
        <p className="MsoNormal">By accepting this agreement, the <strong>Franchisee</strong>&nbsp;is granted non-exclusive rights by <strong>Buz4U</strong>&nbsp;to onboard local businesses to the Franchisor&rsquo;s platform, subject to the terms below.</p>
        <p className="MsoNormal"><strong>3. Franchisee Responsibilities</strong></p>
        <p className="MsoNormal">As a Franchisee, you agree to:</p>
        <p className="MsoNormal">
            <span>&middot;&nbsp;</span>
            <strong>Onboard Businesses</strong>: Promote and onboard businesses to the platform in your designated territory in accordance with the Franchisor&rsquo;s criteria.</p>
        <p className="MsoNormal">
            <span>&middot;&nbsp;</span>
            <strong>Maintain Accuracy</strong>: Submit accurate business data, ensuring all information provided is up to date and compliant with our standards.</p>
        <p className="MsoNormal">
            <span>&middot;&nbsp;</span>
            <strong>Provide Support</strong>: Offer basic support to onboarded businesses for any queries related to listing and managing their profiles.</p>
        <p className="MsoNormal">
            <span>&middot;&nbsp;</span>
            <strong>Marketing</strong>: Actively promote the platform within your assigned region.</p>
        <p className="MsoNormal"><strong>4. Franchisor Responsibilities</strong></p>
        <p className="MsoNormal">The Franchisor will:</p>
        <p className="MsoNormal">
            <span>&middot;&nbsp;</span>
            <strong>Provide Platform Access</strong>: Allow you to access the platform and onboard businesses in the agreed territory.</p>
        <p className="MsoNormal">
            <span>&middot;&nbsp;</span>
            <strong>Offer Support and Training</strong>: Provide necessary resources, training, and support to facilitate the onboarding process.</p>
        <p className="MsoNormal">

            <span>&middot;&nbsp;</span>

            <strong>Verify Milestones</strong>: Verify businesses onboarded by the Franchisee to ensure they meet the Franchisor&rsquo;s requirements.</p>
        <p className="MsoNormal"><strong>5. Payment Terms Based on Milestones</strong></p>
        <p className="MsoNormal">Payment for services rendered by the Franchisee shall be based on <strong>target milestones</strong>&nbsp;as follows:</p>
        <p className="MsoNormal">

            <span>&middot;&nbsp;</span>

            <strong>Initial Milestone</strong>: The first payout will be triggered once the Franchisee successfully onboarded <strong>3 businesses</strong>&nbsp;to the platform. Upon completion, the Franchisee will receive [Percentage/Amount of Payment].</p>
        <p className="MsoNormal">

            <span>&middot;&nbsp;</span>

            <strong>Subsequent Milestones</strong>: Payouts will be based on the value of businesses onboarded each month, with the following milestone structure:</p>
        <p className="MsoNormal">

            <span>o&nbsp;</span>

            <strong>40% of Target for 0 to X Amount</strong>: Once the onboarded businesses' value reaches between <strong>0 and X</strong>&nbsp;amount, the Franchisee will receive <strong>40%</strong>&nbsp;of the payment.</p>
        <p className="MsoNormal">

            <span>o&nbsp;</span>

            <strong>50% of Target for X to Y Amount</strong>: When the value of onboarded businesses falls between <strong>X and Y</strong>, the Franchisee will receive <strong>50%</strong>&nbsp;of the payment.</p>
        <p className="MsoNormal">

            <span>o&nbsp;</span>

            <strong>60% of Target for Y to Z Amount</strong>: For onboarded businesses reaching a value between <strong>Y and Z</strong>, the Franchisee will receive <strong>60%</strong>&nbsp;of the payment.</p>
        <p className="MsoNormal">

            <span>&middot;&nbsp;</span>

            <strong>Milestone Verification</strong>: All payments are subject to the Franchisor&rsquo;s verification that businesses have been successfully onboarded. Payments will be processed within [Number] days of verification.</p>
        <p className="MsoNormal">

            <span>&middot;&nbsp;</span>

            <strong>Invoice Submission</strong>: Franchisee will submit an invoice upon completion of each milestone, indicating the number and value of businesses onboarded for that month.</p>
        <p className="MsoNormal"><strong>6. Duration of Agreement</strong></p>
        <p className="MsoNormal">This Agreement will remain in effect for <strong>one (1) year</strong>&nbsp;from the date of account creation and can be renewed automatically for additional one-year terms unless terminated by either party as outlined in Section 7.</p>
        <p className="MsoNormal"><strong>7. Termination</strong></p>
        <p className="MsoNormal">This Agreement may be terminated as follows:</p>
        <p className="MsoNormal">

            <span>&middot;&nbsp;</span>

            <strong>For Cause</strong>: By either party, with [Number] days&rsquo; notice, in case of breach of any material term of this Agreement.</p>
        <p className="MsoNormal">

            <span>&middot;&nbsp;</span>

            <strong>Without Cause</strong>: By either party, with [Number] days' notice, for any reason.</p>
        <p className="MsoNormal">

            <span>&middot;&nbsp;</span>

            <strong>Effect of Termination</strong>: Upon termination, the Franchisee will lose access to the onboarding tool, and no new businesses can be registered. However, all businesses already onboarded will remain on the platform under the Franchisor&rsquo;s management.</p>
        <p className="MsoNormal"><strong>8. Confidentiality</strong></p>
        <p className="MsoNormal">The Franchisee agrees to maintain the confidentiality of any proprietary information shared by the Franchisor during the course of this Agreement.</p>
        <p className="MsoNormal"><strong>9. Intellectual Property</strong></p>
        <p className="MsoNormal">All intellectual property, including logos, trademarks, and materials provided for marketing purposes, remain the property of the Franchisor. Franchisee is granted a limited license to use these assets solely for the purpose of onboarding businesses onto the platform.</p>
        <p className="MsoNormal"><strong>10. Indemnification</strong></p>
        <p className="MsoNormal">Franchisee agrees to indemnify and hold harmless the Franchisor against any claims, damages, or losses arising out of the Franchisee&rsquo;s performance or failure to meet the obligations under this Agreement.</p>
        <p className="MsoNormal"><strong>11. Dispute Resolution</strong></p>
        <p className="MsoNormal">In the event of any disputes, the parties agree to first attempt to resolve the matter amicably. If the dispute cannot be resolved, it shall be subject to arbitration under the laws of [Country], and the arbitrator's decision shall be final and binding.</p>
        <p className="MsoNormal"><strong>12. Governing Law</strong></p>
        <p className="MsoNormal">This Agreement is governed by the laws of [Country/State], and any disputes arising from this Agreement shall be subject to the exclusive jurisdiction of the courts of [Country/State].</p>
        <p className="MsoNormal"><strong>13. Entire Agreement</strong></p>
        <p className="MsoNormal">This Agreement constitutes the entire understanding between the parties and supersedes all previous agreements, representations, or understandings.</p>
        <p className="MsoNormal">&nbsp;</p>
        <hr align="center" size="2" width="100%" />
        <p>&nbsp;</p>
        <p className="MsoNormal"><strong>Acceptance of Terms</strong></p>
        <p className="MsoNormal">By clicking "I Agree" below, you confirm that you have read, understood, and agree to the terms and conditions of this Franchise Agreement. This digital acceptance will serve as your legal signature and binds you to the terms outlined above.</p>
        <div className="flex-end" onClick={ acceptTerms }>
            <button className="btn btn-primary">I Agree</button>
        </div>
    </div>
}
export default function Agreed(attr) {
    const [user,setUser]=React.useState(attr.user);
    console.log(attr)
    return <div className="cards">
        <Form.Form title="Personal Information">
            <Form.Input
                label="Name"
                defaultValue={user.first_name}
            />
            <Form.Input
                label="Phone Number"
                defaultValue={user.phone}
            />
            <Form.Input
                label="E-Mail"
                defaultValue={user.email}
            />
            <Form.Input
                label="Address"

            />
        </Form.Form>
        <Form.Form title="Business Information">
            <Form.Input
                label="Business Name"
            />
            <Form.Select
                label="Business Type"
                options={ ["Sole Proprietorship", "Limited Liability Company (LLC)", "Civil Company", "Branch of a Foreign Company", "Free Zone Company", "Public Joint Stock Company (PJSC)", "Private Joint Stock Company (PrJSC)", "Partnership Company", "Holding Company"] }
            />
            <Form.Input
                label="Business Phone"
            />
            <Form.Input
                label="Business E-Mail"
            />
            <Form.Input
                label="Address"
            />

            <Form.Input
                label="Website"
            />
        </Form.Form>
        <Form.Form title="Banking Details">
            <Form.Input label="Beneficiary Name" />
            <Form.Input label="Beneficiary Bank Name" />
            <Form.Input label="Beneficiary Bank Account Number" />
            <Form.Input label="Beneficiary Bank Account Name" />
            <Form.Input label="IBAN (International Bank Account Number)" />
            <Form.Input label="BIC (Bank Identifier Code)" />
            <Form.Input label="Swift Code" />
            <Form.Input label="Bank Address" />
            <Form.Input label="Currency" />
        </Form.Form>
    </div>
    return (<Aggrement />)
}