import React from "react";
import ReactDom from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import {RouteList} from "./route";
let rootElement = document.querySelector("#root");
if (!rootElement) {
  rootElement = document.createElement("div");
  rootElement.id = "root";
  document.body.appendChild(rootElement);
}
rootElement = document.querySelector("#root");
const root = ReactDom.createRoot(rootElement);
root.render(<BrowserRouter><RouteList/></BrowserRouter>);
