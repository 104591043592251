import React from "react";
import QRCode from "react-qr-code";
export default function Header(attr) {
  const [open, setOpen] = React.useState(false);
  const [refLink, setRefLink] = React.useState(false);
  const openDropdown = () => {
    setOpen(!open);
  }
  return (<header className="top-header">
    <div className="container-fluid">
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="h4 text-light mb-0">Buz4U </h1>
        <div className="right-section">
          <button onClick={ e => { setRefLink(true) } } className="btn btn-link refcode-btn">Referral Link</button>
          <div className="dropdown"  >
            <button onClick={ e => { e.preventDefault(); e.stopPropagation(); openDropdown(); } } className="btn btn-link dropdown-toggle d-flex align-items-center text-light" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
              <img src={ `/assets/images/` + attr?.user?.photo ?? "user.png" } alt="Profile" className="rounded-circle me-2" width="40" height="40" />
              <span className="d-none d-md-inline">{ attr?.user?.email }</span>
            </button>
            <ul className={ `dropdown-menu dropdown-menu-end ${open ? "show" : ""}` } aria-labelledby="dropdownMenuButton">
              <li><a className="dropdown-item" href="/profile">Profile</a></li>
              <li><a className="dropdown-item" href="/settings">Settings</a></li>
              <li><hr className="dropdown-divider" /></li>
              <li><button className="dropdown-item" href="#" onClick={ () => { localStorage.clear(); window.location.href = "/"; } }>Logout</button></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    { refLink && <div className="ref-container">
      <div className="ref-link">
        <button className="fa fa-times" onClick={ e => { setRefLink(false) } }></button>
        <div className="title">Your referral link is :</div>
        <span>
          https://buz4u.com/user/add_listing?ref={ attr?.user?.id ?? "" }
          <button className="copy-btn" onClick={ e => {
            navigator.clipboard.writeText(`https://buz4u.com/user/add_listing?ref=${attr?.user?.id ?? ""}`);
            alert(`Coppied`);
            setRefLink(false);
          } }>
            <i className="fa fa-copy"></i>
            Click to copy</button>
        </span>
        <div className="qr-code">
          <QRCode
            value={ `https://buz4u.com/user/add_listing?ref=${attr?.user?.id ?? ""}` }
            size={ 256 }
            includeMargin={ true }
          />
        </div>
      </div>
    </div> }
  </header>)
}