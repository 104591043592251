import { useRoutes,Navigate } from "react-router-dom";
import { User, Visitor } from "../layout";
import * as Page from "../page";
export default function RouteList() {
  const token = localStorage.getItem("token");
  return useRoutes([
    {
      path: "/",
      element: !token ? <Visitor /> : <User />,
      children: !token
        ? [
            {
              path: "/",
              element: <Page.Login />,
            },
            {
              path: "/login",
              element: <Page.Login />,
            },
            {
              path: "/register",
              element: <Page.Visitor.Register />,
            },
            {
              path: "/forgot-password",
              element: <Page.Visitor.ForgotPassword />,
            },
            {
              path: "*",
              element: <Navigate to="/" replace={true} />,
            },
          ]
        : [
            {
              path: "/",
              element: <Page.User.Dashboard />,
            },
            {
              path: "/listings",
              element: <Page.User.Listings />,
            },
            {
              path: "/advertisements",
              element: <Page.User.Advertisements />,
            },
            {
              path: "/payment-due",
              element: <Page.User.PaymentDue />,
            },
            {
              path: "/pending-verification",
              element: <Page.User.PendingVerification />,
            },
            {
              path: "/users",
              element: <Page.User.Users />,
            },
            {
              path:"/pending" ,
              element:<Page.User.PendingVerification />
            },
            {
              path:"/pending/docs/:id",
              element:<Page.User.Verification.Docs />
            },
            {
              path:"/subscriptions",
              element:<Page.User.Subscriptions.Subscription/>
            },
            {
              path:"/subscriptions/change/:id",
              element:<Page.User.Subscriptions.Change/>
            }
          ],
    },
  ]);
  // return useRoutes([
  // 	{
  // 		path:"/",
  // 		element:<Layout.Admin />,
  // 		children:[
  // 			{path:"/admin/category",element:<Admin.Category/>},
  // 			{path:"/admin/course",element:<Admin.Course/>},
  // 		]
  // 	},
  // 	{
  // 		path: "/",
  // 		element: <Layout.Visitor />,
  // 		children: [
  // 			{ path: "/", element: <Home /> },
  // 			{ path: "/about", element: <About /> },
  // 			{ path: "/courses", element: <Courses /> },
  // 			{ path: "/contact", element: <Contact /> },
  // 			{ path: "/policy", element: <Policy /> },
  // 			{ path: "/policy/:policy", element: <Policy /> },
  // 			{ path: "/faq", element: <FAQ /> },
  // 			{ path: "/stop-watch", element: <StopWatch /> },
  // 			{
  // 				path: "user/",
  // 				children: [
  // 					{ path: "login/", element: <User.Login /> },
  // 					{ path: "register/", element: <User.Register /> },
  // 				],
  // 			},
  // 		],
  // 	},
  // ]);
}
