import React from "react";
import { Form } from "../../component";

export default function Advertisements() {
    const [add,setAdd]=React.useState({
        title:"Test"
    })
    return (<>
        <div className="card">
            <div className="card-header">
                <div className="card-title">Book An Advertisement</div>
            </div>
            <div className="card-body">
                <Form.Form>
                    <div className="row">
                        <div className="col-md-12">
                            <Form.Input
                                name="title"
                                label="Title"
                                defalutValue={add.title}
                                onChange={(e)=>setAdd({...add,title:e.target.value})}
                            />
                        </div>
                        <div className="col-md-6">
                            <Form.Input
                                type="url"
                                name="home_link"
                                label="Home Link"
                            />
                        </div>
                        <div className="col-md-6">
                            <Form.Image
                                name="home_image"
                                label="Home Image"
                            />
                        </div>
                        <div className="col-md-6">
                            <Form.Input
                                type="date"
                                name="activatedAt"
                                label="Activated At"
                            />
                        </div>
                        <div className="col-md-6">
                            <Form.Input
                                type="date"
                                name="expiresAt"
                                label="Expires At"
                            />

                        </div>
                        <div className="col-md-6">
                            <Form.Input
                                name="adCost"
                                label="Cost Per Day"
                            />
                        </div>
                        <div className="col-md-6">
                            <Form.Input
                                name="price"
                                label="Total Cost"
                            />

                        </div>
                        <div className="col-md-6">
                            <Form.Input
                                name="business"
                                label="Business"
                            />
                        </div>
                    </div>
                    <input
                        type="hidden"
                        name="user"
                    />
                    <input
                        type="hidden"
                        name="adprice"
                    />
                </Form.Form>
            </div>
        </div>
    </>)
}