import React from "react";

export default function PageLayout(attr){
    return(<>
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb2 mb-3 border-bottom">
        <h1 className="h2">{attr.title}</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
            <div className="btn-group me-2">
                {(attr.new) && <button type="button" className="btn btn-sm btn-outline-secondary" onClick={attr.new}>New</button>}
                {attr.close && <button type="button" className="btn btn-sm btn-outline-secondary" onClick={attr.close}>Close</button>}
                {attr.back && <button type="button" className="btn btn-sm btn-outline-secondary" onClick={attr.back}>Back</button>}
            </div>
        </div>
    </div>
    {attr.children}
    </>)
}