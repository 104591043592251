import React from "react";
export default function GoogleMapInput(attr) {
  const [retry, setRetry] = React.useState(1);
  const mapArea = React.useRef();
  const addressInput = React.useRef();
  React.useEffect(()=>{
    console.log(addressInput.current);
    console.log(`Address: ${attr?.defaultValue?.address_1},${attr?.defaultValue?.city_name}, ${attr?.defaultValue?.state_name},${attr?.defaultValue?.country_name}`)
    addressInput.current.value=`${attr?.defaultValue?.address_1},${attr?.defaultValue?.city_name}, ${attr?.defaultValue?.state_name},${attr?.defaultValue?.country_name}`;
  },[attr?.defaultValue?.address_1,attr?.defaultValue?.country_name,attr?.defaultValue?.state_name,attr?.defaultValue?.city_name])
  React.useEffect(() => {
    if ("googleMap" in window && "google" in window)
      window.googleMap.load({ mapArea, addressInput });
    else
      setTimeout(() => {
        setRetry(retry + 1);
      }, 1000);
  }, [retry]);
  return (
    <>
      <div className="col-md-12">
        <div className="form-group">
          <label>Address</label>
          <input
            ref={addressInput}
            type="text"
            className="form-control"
            name={attr.name ?? "address_1"}
            placeholder="Address"
          />
          <input type="hidden" name="latitude" />
          <input type="hidden" name="longitude" />
        </div>
      </div>
      <div className="col-md-12">
        <div ref={mapArea} className="map-area" hidden></div>
      </div>
    </>
  );
}
