import React from "react";

export default function Image(attr) {
    const [selectedImage, setSelectedImage] = React.useState(null);
    const [previewURL, setPreviewURL] = React.useState(null);
    const [fileURI,setFileURI]=React.useState(null);


    function fileUploaded(event) {
        const file = event.target.files[0]
        if (file && file.type.startsWith("image")) {
            setSelectedImage(file);
            const reader = new FileReader();
            reader.addEventListener("loadend",()=>{
                setPreviewURL(reader.result)
            })
            reader.readAsDataURL(file);
        }else{
            setFileURI(URL.createObjectURL(file))
        }
    }

    return (<>
        <div className="form-group">
            <label htmlFor={ attr.id ?? attr.name }>{ attr.label }</label>
            { previewURL && (
                <div>
                    {previewURL &&  <img src={ previewURL } alt={`${attr.name} Preview`} style={ { width: '200px', height: '200px' } } /> }
                    {fileURI && <iframe src={fileURI} />}
                </div>
            ) }
            <input required={ attr.required } accept={attr.accept ?? "image/jpeg,image/png"} onChange={fileUploaded} type="file" name={ attr.name } id={ attr.id ?? attr.name } className="form-control" />
        </div>
    </>)
}