import React from "react";
import { API } from "../../utils";
export default function Select(attr) {
  const [options, setOptions] = React.useState(attr.options ?? []);
  const [value, setValue] = React.useState("");
  React.useEffect(() => {
    if (!attr?.optionApi) return;
    let api = new API();
    const method = (attr?.method ?? "get").toLowerCase();
    api[method](attr?.optionApi).then((resp) => {
      if (resp.data) {
        setOptions(resp.data);
      }
    });
  }, []);
  React.useEffect(() => {
    if (!attr.options) return setOptions([]);
    setOptions(attr.options);
  }, [attr.options]);
  React.useEffect(()=>{
    setValue(attr.value ?? attr.defaultValue??"");
    if(attr.onChange)attr.onChange(new CustomEvent("change",{detail:{value:attr.value ?? attr.defaultValue??""}}));
  },[attr.defaultValue,attr.value,options])
  return (
    <>
      <div className="form-group">
        <label htmlFor="">{ attr.label }</label>
        <select
          onChange={function (event) {
            setValue(event.target.value);
            if(attr.onChange)attr.onChange(event);
          }}
          value={ value }
          name={ attr.name }
          id={ attr.id ?? attr.name }
          className="form-control"
        >
          <option value="" disabled={ true } >
            Please Select
          </option>
          { options.map((option) => {
            if (typeof option === "string")
              return (
                <option value={ option } key={option }>
                  { option }
                </option>
              );
            return (
              <option value={ option.id } key={ option.id }>
                { option.name }
              </option>
            );
          }) }
        </select>
      </div>
    </>
  );
}
