import React from "react";

export default function Form(attr) {
  return (
    <>
      <form
        action={attr.action}
        method={attr.method}
        encType={attr.encType}
        onSubmit={attr.onSubmit}
      >
        <div className="flex-space-between">
          <div className="title">{attr.title ?? ""}</div>
          <button type="button" className="close" onClick={attr.onClose}>
            &times;
          </button>
        </div>
        {attr.children}
      </form>
    </>
  );
}
