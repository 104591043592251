import React from "react";
import {Outlet} from "react-router-dom"
export default function Visitor(){
    return(<div className="container animation-fadein-easeout">
            <div className="row justify-content-center">
                <div className="col-12">
                    <Outlet/>
                </div>
            </div>
        </div>)
}