import React from "react";
import { validateAndSubmit } from "../utils";
export default function Login(){
    const[user,setUser]=React.useState({
        email:"",
        password:""
    })
    const login=async (e)=>{
        e.preventDefault()
        const resp=await validateAndSubmit(e);
        if(resp.token){
            localStorage.setItem("token",resp.token);
            window.location.href="/";
        }
        console.log(resp);
    }
    return(<div className="login-container p-4 p-sm-5 bg-white rounded-4 shadow-lg p-relative">
        <div className="text-center mb-4">
            <img src="https://buz4u.com/assets/front/img/logo-new.jpeg" alt="buz4u Logo" className="img-fluid mb-3 mw-150" />
            <h1 className="fw-bold text-primary">Franchisee Panel</h1>
        </div>
        <form method="POST" action="/api/v1/login" onSubmit={login}>
            <div className="mb-3">
                <input type="email" className="form-control form-control-lg" onChange={e=>setUser({...user,email:e.target.value})} name="email" value={user.email} placeholder="Username" required />
            </div>
            <div className="mb-3">
                <input type="password" className="form-control form-control-lg" name="password" value={user.password} onChange={e=>setUser({...user,password:e.target.value})} placeholder="Password" required />
            </div>
            {/* <div className="mb-3 form-check">
                <input type="checkbox" className="form-check-input" id="rememberMe" name="rememberMe" />
                <label className="form-check-label" for="rememberMe">Remember me</label>
            </div> */}
            <button type="submit" className="btn btn-primary btn-lg w-100" onClick={login}>Log In</button>
        </form>
        <div className="mt-3 text-center">
            <a href="/forgot-password" className="text-decoration-none">Forgot Password?</a>
        </div>
        <div className="mt-3 text-center">
            <span>Need Franchisee? </span>
            <a href="/register" className="text-decoration-none fw-bold">Contact Us</a>
        </div>
        <div className="bubbles">
            <div className="bubble bubble-1"></div>
            <div className="bubble bubble-2"></div>
            <div className="bubble bubble-3"></div>
            <div className="bubble bubble-4"></div>
            <div className="bubble bubble-5"></div>
            <div className="bubble bubble-6"></div>
        </div>
    </div>)
}