import React from "react";
import $ from "jquery";
import "datatables.net";
import { PageLayout } from "../../../component";
import { API, userInfo } from "../../../utils";

export function Subscription() {
    const tableRef = React.useRef(null);
    const [plans, setPlans] = React.useState([]);
    const [plan, setPlan] = React.useState("all");
    React.useEffect(() => {
        if ($.fn.dataTable.isDataTable(tableRef.current)) {
            tableRef.tab.destroy(false);
        }
        userInfo.me().then(resp => {
            document.title=`${plan}`;
            let role = "";
            if (resp?.roles instanceof Array) role = resp.roles.join("");
            switch (role) {
                case "admin":
                case "franchise":
                    const tab=$(tableRef.current).DataTable({
                        processing: true,
                        serverSide: true,
                        ajax: (data, callback) => {
                            const api = new API();
                            api
                                .get(`/api/v1/businesses?plan_name=${plan}&start=${data.start}&length=${data.length}&search=${data.search.value}&order=${JSON.stringify(data.order)}&columns=${JSON.stringify(data.columns)}`)
                                .then(resp => {
                                    callback({
                                        draw: data.draw,
                                        recordsTotal: resp.recordsTotal,
                                        recordsFiltered: resp.recordsFiltered,
                                        data: resp.data,
                                    });
                                })
                                .catch(err => {
                                    console.log(err);
                                })
                        },
                        columns: [
                            { data: "id", name: "ID", title: "ID" },
                            { data: "company_name", title: "Company Name", name: "company_name" },
                            {
                                data: "bussiness_category",
                                title: "Business Category",
                                name: "bussiness_category"
                            },
                            {
                                data: "plan_name",
                                title: "Plan",
                                name: "plan_name"
                            },
                            {
                                render: (d, t, r, m) => {
                                    if (!r.plan_expires) return "N/A";
                                    let dt = new Date(r.plan_expires);
                                    return dt.toLocaleDateString();
                                },
                                title: "Plan Expiry",
                                name: "plan_expires"
                            },
                            {
                                render: (d, t, r, m) => {
                                    return `<a 
                                      href="/subscriptions/change/${r.id}"
                                    ><i class="fas fa-retweet"></i>
                                     Change Plan
                                    </a>`;
                                },
                                title: "",
                            },
                        ],
                        order: [0, "desc"],
                        paging: true,
                        searching: false,
                        ordering: true,
                        responsive: true
                    })
                    tableRef.tab=tab;
                    break;
                default:
                    break;
            }
        })
    }, [plan])
    React.useEffect(() => {
        const api=new API();
        api.get("/api/v1/plans").then(resp=>{
            setPlans(resp?.data??[]);
            console.log(resp)
        })
    },[]);
    React.useEffect(()=>{
        tableRef.current?.ajax?.reload();
    },[plan]);
    return (<>
    {/* <ul>
        <li onClick={()=>setPlan("all")}>All</li>
        {plans.map(item=><li key={item.id} onClick={()=>{
            console.log(item.name)
            setPlan(item.name)
            }}>{item.name}</li>)}
    </ul> */}
        <PageLayout
            title="Subscriptions"
        >
            <table ref={ tableRef }></table>
        </PageLayout>
    </>)
}
export {default as Change} from "./change"