import React from "react";

export default function Tags(attr) {
    const [value,setValue]=React.useState("");
    const [fldValue,setFldValue]=React.useState("");
    React.useEffect(()=>{
        try {
            const val=JSON.parse(attr.defaultValue);
            setValue(val.map(v=>v.value).join(", "));
            setFldValue(attr.defaultValue);
        } catch (error) {
            setValue("");
        }
    },[attr.defaultValue]);
    function valueChanged(e){
        if(e.target.innerText.length===0)return;
        let data=new Set(e.target.innerText.split(",").filter(v=>v.trim()!=="").map(v=>v.trim()));
        
        setFldValue(JSON.stringify(Array.from(data).map(v=>({value:v.trim()}))));
        // setFldValue(e.target.value)
    }
    return (<>
        <div className="form-group">
            <label htmlFor="">{ attr.label }</label>
            <div
                id={ attr.id ?? attr.name }
                required={ attr.required }
                className="form-control"
                contentEditable={ true }
                onInput={valueChanged}
                dangerouslySetInnerHTML={ { __html: value } }
            ></div>
            <input type="hidden" className="form-control" name={attr.name} value={fldValue} />
        </div>
    </>)
}