import API from "./API";
class Notification{
  constructor(message,threshold=300000){
    console.log("Loading Notification");
    let notification=document.querySelector(".notification");
    if(!notification){
      notification=document.createElement("div");
      notification.classList.add("notification");
      document.body.append(notification);
    }
    notification.innerText=message;
    console.log(notification);
    setTimeout(()=>{
      notification.remove();
    },threshold);
  }
}
async function validateAndSubmit(e) {
  e.preventDefault();
  const target = e.target;
  const form = target.tagName === "FORM" ? target : target.form;
  if (!form.checkValidity()) {
    let invalidFields = form.getInvalidInputs();
    invalidFields.forEach((inp) => {
      inp.validity.valueMissing
        ? inp.showError("Required")
        : inp.showError("Invalid");
      inp.addEventListener(
        "keyup",
        () => {
          inp.clearError();
        },
        { once: true },
      );
    });
    invalidFields[0].focus();
    return;
  }
  const action = form.getAttribute("action");
  const method = form.getAttribute("method");
  if (form.hasFile()) {
    const token = localStorage.getItem("token");
    const formData = new FormData(form);
    const resp = await fetch(action, {
      method: method.toUpperCase(),
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if("message" in resp && resp.message!=="")new Notification(resp.message);
    return await resp.json();
  } else {
    const api = new API();
    const data = form.getData();
    const resp = await api[method.toLowerCase()](action, data);
    if(resp.message && resp.message!=="")new Notification(resp.message);
    return resp;
  }
}
export default validateAndSubmit;
