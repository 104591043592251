import React from "react";
import { useParams } from "react-router-dom";
import { API, validateAndSubmit } from "../../../utils";
import { Form, PageLayout } from "../../../component";
const today = new Date().toISOString().split("T")[0];
export default function Change() {
    const nextMonth = new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString().split("T")[0];
    const [plans, setPlans] = React.useState([]);
    const [business, setBusiness] = React.useState({});
    const [country, setCountry] = React.useState("");
    const [currency, setCurrency] = React.useState({});
    const [plan, setPlan] = React.useState({});
    const { id } = useParams();
    console.log({business});
    
    React.useEffect(() => {
        const api = new API();
        api.get("//microservice.buz4u.com/api/v1/country").then(resp => {
            if ("cn" in resp) setCountry(resp.cn)
        })
    }, [])
    React.useEffect(() => {
        if (!country || country.length === 0) return;
        const api = new API();
        api.get(`//microservice.buz4u.com/api/v1/countries/${country}`).then(resp => {
            if ("country" in resp) setCurrency(resp.country.currency);
        })
    }, [country]);
    React.useEffect(() => {
        if (!currency) return;
        const api = new API();
        api.get("/api/v1/plans").then(resp => {
            setPlans(resp.data);
        })
    }, [currency])
    React.useEffect(() => {
        const api = new API();
        api.get(`/api/v1/listing/${id}`).then(resp => {
            setBusiness(resp.data);
        })
    }, [id])
    React.useEffect(() => {
        if (!plans || plans.length === 0) return;
        if (!business) return;
        if (!(business?.subscribed_plans instanceof Array)) return;
        const unpaidPlan = business?.subscribed_plans?.find(item => item.payment_status === "unpaid");
        if (unpaidPlan) setPlan(unpaidPlan);
    }, [plans, business])
    console.log(plans);
    return (<>
    <div className="card">
        <div className="card-header">
            <div className="card-title">{business?.company_name}</div>
        </div>
        <div className="card-body">
            <ul className="info">
                <li>
                    <strong>Current Plan:</strong>
                    {business?.plan_name}
                </li>
                <li>
                    <strong>Valid from:</strong>
                    {business?.plan_start}
                
                </li>
                <li>
                    <strong>Valid upto:</strong>
                    {business?.plan_expires}
                </li>
            </ul>
        </div>
    </div>
        <PageLayout title={ `${business?.company_name} - Change Plan` }>
            <div className="plans">
                <Form.Form
                    action={ `/api/v1/listing/${business?.id ?? ""}/plans${(plan?.id)? `/${plan.id}`:""}` }
                    method="post"
                    onClose={e=>{
                        window.location.pathname="/subscriptions";
                    }}
                >
                    { plans.map(item => {
                        if(item.name ===business?.plan_name)return;
                        const price = item.price.find(rate => rate.currency === currency?.code);
                        return <div className={ `plan ${(plan.plan === item.id) ? "active" : ""}` } key={ item.id } onClick={ e => {
                            setPlan({ ...plan, plan: item.id, amount: price.amount, currency: price.currency, business: business.id, payment_status: 'paid', payment_mode: 'cash' })
                        } }>
                            <div className="name">{ item.id }: { item.name }</div>
                            <div className="price">
                                { price?.currency } { price?.amount }
                            </div>
                        </div>
                    }) }
                    { (plan?.plan) ? <>
                        <input type="hidden" name="business" value={ plan.business ?? business.id ?? "" } />
                        <input type="hidden" name="plan" value={ plan.plan ?? "" } />
                        <input type="hidden" name="currency" value={ plan.currency ?? "" } />
                        <input type="hidden" name="amount" value={ plan.amount ?? "" } />
                        <input type="hidden" disabled name="payment_status" value="paid" />
                        <input type="hidden" name="payment_mode" value="cash" />
                        <input type="hidden" name="activated_at" value={today} />
                        <Form.Input label="Payment Date" type="date" name="payment_date" defaultValue={ today } />
                        <Form.Input label="Expires On" type="date" name="expires_at" defaultValue={ nextMonth } />
                        <div className="form-group">
                            <button type="button" onClick={ async e => {
                                await validateAndSubmit(e);
                                window.location.pathname="/subscriptions";
                            } } className="btn btn-primary">
                                Accepting Payment of { plan?.currency } { plan?.amount }
                            </button>
                        </div>
                    </> : null }
                </Form.Form>
                {/* <ul>
                    { Object.keys(plan).map(key => (<li key={ key }>
                        <strong>{ key }</strong> : { plan[key] }
                    </li>)) }

                </ul> */}
            </div>
        </PageLayout>
    </>)
}