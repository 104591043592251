import React from "react";
import { API } from "../../utils";
import $ from "jquery";
import "datatables.net";
import NewBusiness from "./Listings/New";

export { default as NewBusiness } from "./Listings/New";
export default function Listings() {
  const [showForm, setShowForm] = React.useState(false);
  const [counter, setCounter] = React.useState(1);
  const [currentValue, setCurrentValue] = React.useState(null);
  const tableRef = React.useRef(null);
  React.useEffect(() => {
    document.addEventListener("open-business", async (e) => {
      const api = new API();
      let business = await api.get(`/api/v1/listing/${e.detail.id}`);
      setCurrentValue(business.data);
      setShowForm(true);
    });
  }, []);
  React.useEffect(() => {
    if ($.fn.dataTable.isDataTable(tableRef.current)) return;
    if (tableRef.current) {
      console.log(tableRef.current);
      const table = $(tableRef.current).DataTable({
        processing: true,
        serverSide: true,
        ajax: (data, callback) => {
          let api = new API();
          api
            .get(
              `/api/v1/businesses?start=${data.start}&length=${data.length}&search=${data.search.value}&order=${JSON.stringify(data.order)}&columns=${JSON.stringify(data.columns)}`,
            )
            .then((resp) => {
              // Format the data for DataTables
              callback({
                draw: data.draw,
                recordsTotal: resp.recordsTotal,
                recordsFiltered: resp.recordsFiltered,
                data: resp.data,
              });
            })
            .catch((error) => {
              console.error("There was an error fetching the data!", error);
            });
        },
        columns: [
          { data: "id", title: "ID", name: "id" },
          { data: "company_name", title: "Company Name", name: "company_name" },
          {
            data: "bussiness_category",
            title: "Category",
            name: "bussiness_category",
          },
          { data: "state_name", title: "State", name: "state_name" },
          { data: "city_name", title: "City", name: "city_name" },
          { data: "bussiness_email", title: "E-Mail", name: "business_email" },
          { title: "Verified", name: "verified",render:(d,t,r,m)=>{
            return (r?.is_verified===1)?"Yes":"No";
          }},
          {
            render: (d, t, r, m) => {
              console.log(r);
              return `<button
                  class="btn btn-sm btn-outline-primary"
                  onclick="openBusiness(${r.id})"
                >
                  Show
                </button>`;
            },
            title: "",
          },
        ],
        order: [0, "desc"],
        paging: true,
        searching: true,
        ordering: true,
        responsive: true,
      });
    }
  }, [counter]);
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Business Listings</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
          <button
            onClick={(e) => {
              setCurrentValue(null);
              setShowForm(true);
            }}
            // href="/listings/new"
            hidden={showForm}
            className="btn btn-sm btn-outline-primary"
          >
            New
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col" hidden={!showForm}>
          <NewBusiness
            onClose={(e) => {
              setShowForm(false);
            }}
            currentValue={currentValue}
          />
        </div>
      </div>
      {/* <NewBusiness showForm={showForm} /> */}
      <table ref={tableRef}></table>
    </>
  );
}
