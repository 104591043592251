import React from "react";
import { PageLayout } from "../../component";
import { API, userInfo } from "../../utils";
import $ from "jquery";
import "datatables.net";

export default function PendingVerification() {
    const tableRef = React.useRef(null);
    React.useEffect(() => {
        if ($.fn.dataTable.isDataTable(tableRef.current)) return;
        userInfo.me().then(resp => {
            const role = resp.roles.join("");
            switch (role) {
                case "admin":
                    $(tableRef.current).DataTable({
                        processing: true,
                        serverSide: true,
                        ajax: (data, callback) => {
                            const api = new API();
                            api
                                .get(`/api/v1/businesses?verified=0&requestVerfication=1`)
                                .then(resp => {
                                    callback({
                                        draw: data.draw,
                                        recordsTotal: resp.recordsTotal,
                                        recordsFiltered: resp.recordsFiltered,
                                        data: resp.data,
                                    });
                                })
                                .catch(err => {
                                    console.log(err);
                                })
                        },
                        columns: [
                            { data: "id", name: "ID", title: "ID" },
                            { data: "company_name", title: "Company Name", name: "company_name" },
                            {
                                data: "bussiness_category",
                                title: "Business Category",
                                name: "bussiness_category"
                            },
                            {
                                data: "city_name",
                                title: "City",
                                name: "city_name"
                            },
                            {
                                data: "state_name",
                                title: "State",
                                name: "state_name"
                            },
                            {
                                data: "country_name",
                                title: "Country",
                                name: "country_name"
                            },
                            {
                                render: (d, t, r, m) => {
                                    if (r.requestVerify ?? false) return `<a href="/pending/docs/${r.id}"><i class="fas fa-folder-open"></i>Docuemnts</a>`;

                                    return `<a 
                                      href="/pending/docs/${r.id}"
                                    ><i class="fas fa-cloud-upload-alt"></i>
                                     Documents
                                    </a>`;
                                },
                                title: "",
                            },
                        ],
                        order: [0, "desc"],
                        paging: true,
                        searching: false,
                        ordering: true,
                        responsive: true
                    })
                    break;

                default:
                    $(tableRef.current).DataTable({
                        processing: true,
                        serverSide: true,
                        ajax: (data, callback) => {
                            const api = new API();
                            api
                                .get(`/api/v1/businesses?verified=0`)
                                .then(resp => {
                                    console.log(resp);
                                    callback({
                                        draw: data.draw,
                                        recordsTotal: resp.recordsTotal,
                                        recordsFiltered: resp.recordsFiltered,
                                        data: resp.data,
                                    });
                                })
                                .catch(err => {
                                    console.log(err);
                                })
                        },
                        columns: [
                            { data: "id", name: "ID", title: "ID" },
                            { data: "company_name", title: "Company Name", name: "company_name" },
                            {
                                data: "bussiness_category",
                                title: "Business Category",
                                name: "bussiness_category"
                            },
                            {
                                data: "city_name",
                                title: "City",
                                name: "city_name"
                            },
                            {
                                data: "state_name",
                                title: "State",
                                name: "state_name"
                            },
                            {
                                data: "country_name",
                                title: "Country",
                                name: "country_name"
                            },
                            {
                                render: (d, t, r, m) => {
                                    if (r.requestVerify ?? false) return `<a href="/pending/docs/${r.id}"><i class="fas fa-folder-open"></i>Docuemnts</a>`;

                                    return `<a 
                                      href="/pending/docs/${r.id}"
                                    ><i class="fas fa-cloud-upload-alt"></i>
                                     Documents
                                    </a>`;
                                },
                                title: "",
                            },
                        ],
                        order: [0, "desc"],
                        paging: true,
                        searching: false,
                        ordering: true,
                        responsive: true
                    })
                    break;
            }
        })
    }, [])
    return (<>
        <PageLayout title="Pending Verifications">
            <table ref={ tableRef } ></table>
        </PageLayout>
    </>)
}