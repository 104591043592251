import React from "react";
import { Form } from "../../../component";
import { API, validateAndSubmit } from "../../../utils";
import { GoogleMapInput } from "../../../component/Form";

export default function NewBusiness(attr) {
  const [state, setState] = React.useState({});
  const [country, setCountry] = React.useState({});
  const [countries, setCountries] = React.useState([]);
  const [states, setStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [currentValue, setCurrentValue] = React.useState(null);

  React.useEffect(() => {
    const api = new API();
    api.get("//microservice.buz4u.com/api/v1/countries").then((resp) => {
      setCountries(
        resp.countries.map((cn) => ({
          id: cn?.name,
          name: cn?.name,
          code: cn?.codes?.iso2,
          currency: cn.currency.code,
        })),
      );
    });
  }, []);
  React.useEffect(() => {
    const api = new API();
    api.get("//microservice.buz4u.com/api/v1/country").then((resp) => {
      let cn = countries.find(
        (c) => c?.code?.toUpperCase() === resp?.cn?.toUpperCase(),
      );
      if (!cn) return;
      setCountry(cn);
      setCurrentValue({ ...currentValue, country_name: cn.name });
    });
  }, [countries]);
  React.useEffect(() => {
    if (!country || !country.code) return;
    const api = new API();
    api
      .get(`//microservice.buz4u.com/api/v1/countries/${country.code}`)
      .then((resp) => {
        if (!resp.country || !resp.country.states) return;
        setStates(
          Object.values(resp.country.states).map((st) => ({
            ...st,
            id: st.name,
          })),
        );
      });
  }, [country]);
  React.useEffect(() => {
    setCities(state.cities);
    setCurrentValue({ ...currentValue, state_name: state.name });
  }, [state]);
  React.useEffect(() => {
    setCurrentValue(attr.currentValue);
  }, [attr]);
  const currencies = ["INR", "AED", "PKR", "USD"];
  return (
    <>
      <Form.Form
        title={
          (currentValue === null || !currentValue.id) ? "New Business" : `Update Business Details ${currentValue.id}`
        }
        onSubmit={ async e => {
          e.preventDefault();
          let resp = await validateAndSubmit(e);
          if (resp.message && resp.message !== "") alert(resp.message);
          if (resp.status && resp.status === "success") {
            window.location.href = "/listings";
          }
        }
        }
        hidden={ attr.showForm }
        action={
          (currentValue === null || !currentValue.id)
            ? "/api/v1/listing/new"
            : `/api/v1/listing/${currentValue.id}`
        }
        method="post"
        enctype="multipart/form-data"
        onClose={ attr.onClose }
      >
        <div className="row">
          <div className="col">
            <Form.Select
              label="Subscription Plan"
              name="plan"
              optionApi="/api/v1/plans"
              method="get"
              defaultValue={ 1 }
              required
              onChange={ (e) => {
              } }
            />
          </div>
          <div className="col">
            <Form.Select
              label="Currency"
              name="currency"
              options={ currencies }
              required
              value={ country?.currency }
            />
          </div>
          <div className="col">
            <Form.Select
              label="Business Category"
              name="category"
              optionApi="/api/v1/category"
              method="get"
              required
              defaultValue={
                currentValue === null
                  ? ""
                  : (currentValue.bussiness_category ?? "")
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Form.Input
              type="text"
              label="Company Name"
              name="company_name"
              defaultValue={ currentValue?.company_name ?? "" }
              required={ true }
            />
          </div>
          <div className="col">
            <Form.Input
              type="text"
              label="Legal Business Name"
              name="legal_name"
              defaultValue={ currentValue?.legel_name ?? "" }
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Form.Input
              type="url"
              name="website"
              label="Website"
              defaultValue={ currentValue?.website ?? "" }
            />
          </div>
          <div className="col">
            <Form.Input
              type="email"
              name="business_email"
              label="Business E-Mail"
              required
              defaultValue={ currentValue?.bussiness_email ?? "" }
            />
          </div>
          <div className="col">
            <Form.Input
              type="tel"
              name="business_phone"
              required
              label="Business Phone (Mobile)"
              defaultValue={ currentValue?.business_phone ?? "" }
            />
          </div>

          <div className="col">
            <Form.Input
              type="tel"
              name="phone"
              label="Business Phone (Landline)"
              defaultValue={ currentValue?.phone ?? "" }
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Form.Select
              label="Country"
              name="country"
              required
              options={ countries }
              onChange={ (e) => {
                if (!countries || !(countries instanceof Array)) return;
                const value = e?.target?.value ?? e?.detail?.value ?? "";
                let cn = countries.find((c) => (value) === c.name);
                if (!cn) return;
                setCountry(cn);
                setCurrentValue({ ...currentValue, country_name: cn.name });
              } }
              defaultValue={ currentValue?.country_name }
            />
          </div>
          <div className="col">
            <Form.Select
              label="State"
              name="state"
              options={ states }
              required
              onChange={ (e) => {
                if (!states || !(states instanceof Array)) return console.log(`states not loaded`);
                const value = e?.target?.value ?? e?.detail?.value ?? "";
                let st = states.find((s) => value === s.name);
                if (!st) return;// console.log(`state not found`);
                setState(st);
                setCurrentValue({ ...currentValue, state_name: st.name });
                console.log("state updated")
              } }
              defaultValue={ currentValue?.state_name }
            />
          </div>
          <div className="col">
            <Form.Select
              label="City"
              name="city"
              options={ cities }
              required
              onChange={ (e) => {
                if (!cities || !(cities instanceof Array)) return;
                const value = e?.target?.value ?? e?.detail?.value ?? "";
                let ct = cities.find((c) => value === c);
                setCurrentValue({ ...currentValue, city_name: ct });
              } }
              defaultValue={ currentValue?.city_name }
            />
          </div>
        </div>
        <div className="row">
          <GoogleMapInput defaultValue={ currentValue ?? null } />
        </div>
        <div className="row">
          <div className="col">
            <Form.Editor
              label="summary"
              name="summary"
              defaultValue={ currentValue?.summary ?? "" }
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Form.Editor
              label="About Business"
              name="about"
              defaultValue={ currentValue?.about ?? "" }
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Form.Tags
              name="tags"
              label="Tags"
              type="tags"
              defaultValue={ currentValue?.tags ?? "" }
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Form.Tags
              name="keywords"
              label="Keywords"
              type="tags"
              required
              defaultValue={ currentValue?.keywords ?? "" }
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Form.Image name="logo" label="Logo" />
          </div>
          <div className="col">
            <Form.Image name="thumbnail" label="Thumbnail" />
          </div>
          <div className="col">
            <Form.Image name="banner" label="Banner" />
          </div>
        </div>
        { (!currentValue || !currentValue.user_id) && (
          <>
            <div className="row">
              <div className="col">
                <Form.Input required label="Business Owner's name" name="name" />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Form.Input
                  type="email"
                  required
                  label="Business Owner's EMail"
                  name="email"
                />
              </div>
              <div className="col">
                <Form.Input
                  type="tel"
                  required
                  label="Business Owner's Phone"
                  name="phone"
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Form.Input required type="password" label="Password" name="password" />
              </div>
              <div className="col">
                <Form.Input
                  type="password"
                  required
                  label="Confirm Password"
                  name="cnfpassword"
                />
              </div>
            </div>
          </>
        ) }
        <div className="row">
          <div className="col">
            <button
              type="button"
              onClick={ async e => {
                e.preventDefault();
                let resp = await validateAndSubmit(e);
                if(!resp)return;
                if ("message" in resp && resp.message !== "") alert(resp.message);
                if ("status" in resp && resp.status === "success") {
                  window.location.href = "/listings";
                }
              } }
              className="btn btn-primary">Submit</button>
          </div>
        </div>
      </Form.Form>
    </>
  );
}
