import React from 'react'
import { API } from '../../utils'

function Card(attr){
    return <div className="card border-left-primary shadow h-100 py-2">
    <div className="card-body">
      <div className="row no-gutters align-items-center">
        <div className="col mr-2">
          <div className="h5 mb-0 font-weight-bold text-gray-800">{attr?.count??""}</div>
          <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">{attr?.name??""}</div>
        </div>
        <div className="col-auto">
          <i className={`${attr?.icon??""} fa-2x text-gray-300`}></i>
        </div>
      </div>
    </div>
  </div>
}
export default function Dashboard() {
  const [summary,setSummary]=React.useState({
    listing:0,
    payment:0,
    verification:0,
    subscription:0
  })
  React.useEffect(()=>{
    const api=new API();
    api
      .get("/api/v1/summary")
      .then(r=>{
        if((typeof r) !== "object"){
          return;
          localStorage.clear();
          window.location.href="/";
        }else{
          setSummary(r.data);
        }
      })
  },[])
    return <>
        <h1>Welcome </h1>
        <div className="row">
          <div className="col-xl-3 col-md-6 mb-4">
            <Card icon="fas fa-users" name="Total Listing" count={summary.listing}/>
          </div>
          <div className="col-xl-3 col-md-6 mb-4">
          <Card icon="fas fa-dollar-sign" name="Payment Due" count={summary.payment}/>
          </div>
          <div className="col-xl-3 col-md-6 mb-4">
          <Card icon="fas fa-clipboard-list" name="Pending Verifications" count={summary.verification}/>
          </div>
          <div className="col-xl-3 col-md-6 mb-4">
            <Card icon="fas fa-file-invoice" name="Expiring Subscriptions" count={summary.subscription}/>
            </div>
        </div>
        {/* <ul>
            <li>Listing</li>
            <li>Payment Due</li>
            <li>Pending Verifications</li>
            <li>Expiring Subscriptions</li>
            <li>Advertisement(Awaiting)</li>
        </ul> */}
    </>
}