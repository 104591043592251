import React from "react";

export default function Register(){
    return(<div className="login-container p-4 p-sm-5 bg-white rounded-4 shadow-lg p-relative">
        <div className="text-center mb-4">
            <img src="https://buz4u.com/assets/front/img/logo-new.jpeg" alt="buz4u Logo" className="img-fluid mb-3 mw-150" />
            <h1 className="fw-bold text-primary">Franchisee Panel</h1>
        </div>
        <form method="GET" action="#">
        <div className="mb-3">
                <input type="text" className="form-control form-control-lg" name="name" placeholder="Name" required />
            </div>
            <div className="mb-3">
                <input type="text" className="form-control form-control-lg" name="name" placeholder="E-Mail" required />
            </div>
            <div className="mb-3">
                <input type="text" className="form-control form-control-lg" name="name" placeholder="Phone Number" required />
            </div>
            <div className="mb-3">
                <input type="text" className="form-control form-control-lg" name="name" placeholder="Address" required />
            </div>
            <button type="submit" className="btn btn-primary btn-lg w-100">Request Franchise</button>
        </form>
        <div className="mt-3 text-center">
            <a href="/forgot-password" className="text-decoration-none">Forgot Password?</a>
        </div>
        <div className="mt-3 text-center">
            <span>Have credentials? </span>
            <a href="/" className="text-decoration-none fw-bold">Login</a>
        </div>
        <div className="bubbles">
            <div className="bubble bubble-1"></div>
            <div className="bubble bubble-2"></div>
            <div className="bubble bubble-3"></div>
            <div className="bubble bubble-4"></div>
            <div className="bubble bubble-5"></div>
            <div className="bubble bubble-6"></div>
        </div>
    </div>)
}