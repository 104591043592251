import React from "react";
import { useParams } from "react-router-dom";
import { API, userInfo, validateAndSubmit } from "../../../utils";
import { Form, PageLayout } from "../../../component";
function ActionButtons(attr) {
    const { doc, crud, business } = attr;
    switch (attr.role ?? "") {
        case "admin":
            if(["approved","rejected"].indexOf(doc.status)>-1)return <></>
            return <div className="actions">
                <button onClick={e=>{attr.crud.reject(doc.id)}} className="btn btn-danger btn-small">Reject</button>
                <button onClick={e=>{attr.crud.approve(doc.id)}} className="btn btn-success btn-small">Approve</button>
            </div>
            break;

        default:
    return <div className="actions">
        { (business?.requestVerify == 0) && 
        <button className="btn btn-danger btn-small" onClick={ e => { crud.delete(doc.id) } }  >Delete</button> }
    </div>
            break;
    }
}
function BusinessActions(attr) {
    const { business, crud } = attr;
    switch (attr.role) {
        case "admin":
    return <>
    <div className="flex-end">
        {business.is_verified === 0 ?<button className="btn btn-primary btn-small" onClick={ e => { crud.confirmVerify(business.id) } }>Mark as Verified</button>:<span>Verified</span>}
    </div>
    </>        
            break;
    
        default:
    return <div className="flex-end">
    {
        business?.requestVerify ?
            "Verification Requested" :
            <button className="btn btn-primary" onClick={ e => { crud.requestVerify(business.id) } }>Request Verification</button>
    }
</div>
            break;
    }
}
export default function Docs() {
    const { id } = useParams();
    const [showForm, setShowForm] = React.useState(false);
    const [business, setBusiness] = React.useState({});
    const [docs, setDocs] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [user, setUser] = React.useState({});
    const [role, setRole] = React.useState("");
    React.useEffect(() => {
        userInfo.me().then(resp => {
            setUser(resp.user);
            setRole(resp.roles[0]);
        })
    }, []);
    console.log({
        user,
        role
    })
    React.useEffect(() => {
        const api = new API();
        api
            .get(`/api/v1/listing/${id}`)
            .then(resp => {
                if (!("data" in resp)) return;
                setBusiness(resp.data);
            })
            .catch(err => {
                console.log(err);
            })
    }, [id]);
    React.useEffect(() => {
        const api = new API();
        api
            .get(`/api/v1/listing/${id}/docs`)
            .then(resp => {
                if (!("data" in resp)) return;
                setDocs(resp.data)
            })
            .catch(err => {
                console.log(err);
            })
    }, [id])
    const crud = {
        approve:async (e)=>{
            setLoading(true);
            let api=new API();
            let resp=await api.put(`/api/v1/listing/${id}/docs/${e}`,{status:"approve"});
            setLoading(false);
            if ("data" in resp)
                setDocs(resp.data);
        },
        reject:async (e)=>{
            setLoading(true);
            let api=new API();
            let resp=await api.put(`/api/v1/listing/${id}/docs/${e}`,{status:"reject"});
            setLoading(false);
            if ("data" in resp)
                setDocs(resp.data);
            },
        confirmVerify:async(e)=>{
            setLoading(true);
            let api=new API();
            let resp=await api.put(`/api/v1/listing/${id}/verify`,{status:"approve"});
            setLoading(false);
            if ("data" in resp)
                setBusiness(resp.data);
            if("business" in resp)
                setBusiness(resp.business)
        },
        close: () => {
            setShowForm(false)
        },
        openForm: function () {
            setShowForm(true);
        },
        upload: async (e) => {
            setLoading(true)
            await validateAndSubmit(e);
            setShowForm(false)
            let api = new API();
            let resp = await api.get(`/api/v1/listing/${id}/docs`)
            setLoading(false);
            if ("data" in resp)
                setDocs(resp.data);
        },
        delete: async (e) => {
            let api = new API();
            setLoading(true);
            let resp = await api.delete(`/api/v1/listing/${id}/docs/${e}`)
            resp = await api.get(`/api/v1/listing/${id}/docs`);
            setLoading(false);
            if ("data" in resp)
                setDocs(resp.data);
        },
        requestVerify: async function (id) {
            const api = new API();
            await api.get(`/api/v1/listing/${id}/verify`);
            window.location.pathname = "/pending-verification";
        }
    }
    return (<>
        { loading && <div className="loading">Loading</div> }
        {
            showForm ? <Form.Form
                title="Upload Document"
                action={
                    `/api/v1/listing/${id}/docs`
                }
                method="post"
                enctype="multipart/form-data"
                onClose={ e => { crud.close() } }
            >
                <Form.Input
                    type="text"
                    label="Document Name"
                    name="name"
                    required
                />
                <Form.Image
                    label="Document"
                    name="file"
                    accept="image/jpeg,image/png,application/pdf"
                    required
                />
                <button type="button" onClick={ crud.upload } className="btn btn-primary">Upload</button>
            </Form.Form> : <PageLayout
                title={ `Documents: ` + business?.company_name }
                new={ business?.requestVerify ? undefined : crud.openForm }
                back={ e => {
                    window.location.href = "/pending-verification"
                } }
            >
                <ul className="docs">
                    { docs.map(doc => <li key={ `doc_${doc.id}` }>
                        <a target="_blank" href={ `/uploads/docs/${doc.file}` }>{ doc.name } [{ doc.status }]</a>
                        <ActionButtons role={ role } doc={ doc } crud={ crud } business={ business } />
                    </li>) }
                </ul>

                <BusinessActions role={ role } business={ business } crud={ crud } />

            </PageLayout> }
    </>)
}
