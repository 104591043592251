import React from "react";
import {Outlet} from "react-router-dom"
import { Header, Sidenav } from "../component";
import API from "../utils/API";
import Agreed from "../component/Agreed";

export default function User() {
    const [user, setUser] = React.useState({});
    const [roles,setRoles]=React.useState([]);
    React.useState(() => {
        const api = new API();
        api.get("/api/v1/me").then(r => {
            if (typeof r !== "object") {
                localStorage.clear();
                window.location.href = "/";
            } else {
                if (r.user)
                    setUser(r.user);
                if(r.roles)setRoles(r.roles);
            }
        }).catch(e => {
            console.log(e)
        })
    }, [])
    if(user.terms===0)return <Agreed user={user}/>
    return (<><Header user={user}/>
        <div className="container-fluid">
            <div className="row">
                <Sidenav user={user} roles={roles[0]??""} />
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 content-wrapper">
                    <Outlet/>
                </main>
            </div>
        </div>

        <footer className="text-center py-4">
            <div className="container">
                <p className="mb-0">&copy; 2024 Buz4u Admin. All rights reserved.</p>
            </div>
        </footer></>)
}