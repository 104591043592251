import React from "react";

export default function Input(attr) {
  return (
    <>
      <div className="form-group">
        <label htmlFor="">{attr.label}</label>
        <input
          type={attr.type ?? "text"}
          name={attr.name}
          id={attr.id ?? attr.name}
          required={attr.required}
          className="form-control"
          defaultValue={attr.defaultValue}
        />
      </div>
    </>
  );
}
